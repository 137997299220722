<template>
  <div id="dashboard">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container fluid="sm" class="dashboard-container">
      <b-row>
        <b-col cols="12" sm="12" lg="8">
          <profile-card
            :user="loggedUser"
            :extendedUser="loggedUser.getTechnician"
            v-bind:editable="true"
          />
          <about-me-card
            :extendedUser="loggedUser.getTechnician">
          </about-me-card>
          <professional-profile-card
            :user="loggedUser"
            :editable="true"
            :extendedUser="loggedUser.getTechnician"
          />
          <languages-card
            :user-languages="loggedUser.getTechnician.getLanguages">
          </languages-card>
          <certification-card
            :userCertifications="loggedUser.getTechnician.getCertifications">
          </certification-card>
          <jobs-card
            :userJobs="loggedUser.getTechnician.getJobs">
          </jobs-card>
          <hardware-card
            :userHardware="loggedUser.getTechnician.getHardware">
          </hardware-card>
        </b-col>
        <b-col cols="12" lg="4" md="12">
          <public-profile-card :user="loggedUser"></public-profile-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import AboutMeCard from '../components/AboutMe/AboutMeCard'
import LanguagesCard from '../components/Languages/LanguagesCard'
import CertificationCard from '../components/Certification/CertificationCard'
import JobsCard from '../components/Jobs/JobsCard'
import HardwareCard from '../components/Hardware/HardwareCard'
import PublicProfileCard from '../components/PublicProfile/PublicProfileCard'
import ProfessionalProfileCard from '../components/ProfessionalProfile/ProfessionalProfileCard'
import ProfileCard from '../components/Profile/ProfileCard'

export default {
  name: 'Dashboard',
  components: {
    ProfileCard,
    ProfessionalProfileCard,
    PublicProfileCard,
    CertificationCard,
    JobsCard,
    HardwareCard,
    LanguagesCard,
    Header,
    AboutMeCard
  },
  created () {
    this.$store.commit('setCurrentProfileTechnician')
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  }
}
</script>

<style lang="less">
  @import 'dashboard.less';
</style>
