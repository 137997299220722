<template>
  <div>
    <private-card
      title="Perfil público"
      eventName="onClickEditPublicProfile"
      :editIcon="false"
    >
      <template v-slot:private-card-body>
        <p>Mira y edita cómo te ven las personas con las que compartes tu link de Avify.</p>
        <div class="custom-url text-sm">
          <div class="custom-url-title">Tu URL personalizada</div>
          <div class="custom-url-actions d-flex justify-content-between align-items-center text-sm flex-wrap">
            <div class="custom-url-actions-text mr-1">{{ userPublicProfileUrl }}</div>
            <div class="custom-url-actions-copy"
                 v-clipboard="userPublicProfileUrl"
                 v-b-tooltip.click.rightbottom.html="copyToClipboardMethod"
                 variant="outline-success"
            >Copiar url</div>
          </div>
        </div>
        <b-link class="w-100 my-4 btn btn-secondary" :to="{ name: 'public-user-profile', params: { slug: userSlug }}" target="_blank">Ver mi perfil público</b-link>
        <p class="text-xs">* Ten en cuenta que cualquier persona con acceso a la URL compartida podrá visualizar la información
          personal que hayas seleccionado compartir en tu perfil público.</p>
      </template>
    </private-card>
    <private-card
      title="Edita tu URL personalizada"
      eventName="onClickEditCustomUrl"
      :editIcon="false"
      v-if="showCustomURL"
    >
      <template v-slot:private-card-body>
        <div class="custom-url text-sm">
          <div class="custom-url-title">Personaliza la URL de tu perfil</div>
          <br />
          <div class="custom-url-actions-text" v-if="!showForm">
            <span>{{ userPublicProfileUrl }}</span>
            <span v-if="!showForm" @click="showForm = !showForm" class="edit-inline-card-info-image"></span>
          </div>
          <div v-if="showForm">
            <validation-observer ref="updateCustomUrl" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(onUpdateCustomUrl)" autocomplete="off" novalidate>
                <validation-provider vid="slug" name="url personalizada" rules="required|alpha_dash" v-slot="validationContext">
                  <b-form-group id="customUrl" label-for="input-custom-url">
                    <b-form-input
                      id="custom-url"
                      name="custom-url"
                      type="text"
                      v-model="editableSlug.slug"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <small>* No uses espacios, símbolos ni caracteres especiales.</small>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <div class="text-center">
                  <b-button block type="submit" variant="primary">
                    Guardar cambios
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </template>
    </private-card>
  </div>
</template>

<script>
import PrivateCard from '../Card/PrivateCard'
import errorMessagesUtil from '../../utils/getErrorMessagesUtil'

export default {
  name: 'PublicProfileCard',
  components: {
    PrivateCard
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    showCustomURL: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      copyToClipboardMethod: {
        title: '<span class="check-circle mr-2"></span>Enlace copiado al portapapeles',
        html: true
      },
      showForm: false,
      editableSlug: {
        slug: null
      }
    }
  },
  mounted: function () {
    this.editableSlug.slug = this.userSlug
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    },
    userPublicProfileUrl () {
      return process.env.VUE_APP_PUBLIC_URL + this.$store.getters.loggedUser.slug
    },
    userSlug () {
      return this.$store.getters.loggedUser.slug
    }
  },
  methods: {
    onUpdateCustomUrl () {
      this.lockScreen()
      this.$store.dispatch('updateUserSlug', this.editableSlug)
        .then(() => {
          this.showForm = false
          this.$store.dispatch('showConfirmationModal')
        })
        .catch(error => {
          const serverErrorMessage = error.response.data.message
          if (serverErrorMessage === 'api.users.slug_already_in_use') {
            this.$refs.updateCustomUrl.setErrors({
              slug: errorMessagesUtil.getErrorMessage(serverErrorMessage)
            })
          }
          this.unlockScreen()
        })
    }
  }
}
</script>

<style scoped lang="less">
  @import 'publicProfile.less';
</style>
